import { extendTheme } from '@chakra-ui/react'

import colors from './colors'
import CustomButton from './components/CustomButton'
import { fonts, fontSizes } from './fonts'
import global from './global'
import textStyles from './textStyles'

const theme = {
  styles: {
    global,
  },
  colors,
  fonts,
  fontSizes,
  textStyles,
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '1024px',
  },
  components: {
    ...CustomButton,
  },
}

export default extendTheme(theme)
