import { StyleInterpolation } from '@/types/chakra'

const textStyles = {
  // Link
  'header-link': {
    fontSize: 'xs',
    fontWeight: '700',
    letterSpacing: '0.1em',
    opacity: '0.75',
  },

  'header-title': {
    fontSize: '7xl',
    fontWeight: '700',
    letterSpacing: '0.1em',
    ['@media (max-width: 768px)']: {
      fontSize: `5xl`,
    },
  },

  'description-text': {
    color: 'light',
    fontSize: 'lg',
    letterSpacing: '0.05em',
    ['@media (max-width: 768px)']: {
      fontSize: `2xl2`,
    },
  },
} as StyleInterpolation

export default textStyles
