import header from './global/layouts/header'

import { StyleInterpolation } from '@/types/chakra'

const global = {
  body: {
    fontSize: `1vw`,
    ['@media (max-width: 768px)']: {
      fontSize: `2vw`,
    },
    lineHeight: 1.5,
    bgColor: 'dark',
    color: 'white',
    overflowX: 'hidden',
  },

  svg: {
    fill: 'currentColor',
    path: {
      fill: 'currentColor',
    },
    'path[fill="none"]': {
      fill: 'none',
      stroke: 'currentColor',
    },
  },

  section: {
    position: 'relative',
    zIndex: 'base',
    contentVisibility: 'visible',
    overflow: 'hidden',
  },

  '.gatsby-image-wrapper': {
    pointerEvents: 'none',
  },

  '.absolute-center': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  ...header,
} as StyleInterpolation

export default global
