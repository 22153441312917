const base = {
  size: {
    xs: '0.625em', // 12px
    sm: '0.7291666666666666em', // 14px
    md: '0.8333333333333334em', // 16px
    lg: '0.9375em', // 18px
    lg2: '1.0416666666666667em', // 20px
    xl: '1.1458333333333333em', // 22px
    '2xl': '1.25em', // 24px
    '2xl2': '1.4583333333333333em', // 28px
    '3xl': '1.5625em', // 30px
    '3xl2': '1.6666666666666667em', // 32px
    '4xl': '1.875em', // 36px
    '4xl2': '2.1875em', // 42px
    '5xl': '2.5em', // 48px
    '6xl': '3.125em', // 60px
    '6xl2': '3.3333333333333335em', // 64px
    '7xl': '3.75em', // 72px
    '8xl': '5em', // 96px
    '9xl': '6.666666666666667em', // 128px
  },
}

export { base }
