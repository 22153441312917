import { StyleConfig } from '@chakra-ui/react'

import colors from '../colors'

const components: Record<string, StyleConfig> = {
  CustomButton: {
    baseStyle: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    variants: {
      primary: () => ({
        borderWidth: '0.052083333333333336em', // 1px
        borderColor: colors.primary,
        bgColor: 'transparent',
        position: 'relative',
        borderRadius: '2.03125em', // 39px
      }),
      secondary: () => ({
        bgColor: 'rgba(0, 0, 0, 0.5)',
        position: 'relative',
      }),
    },
  },
}

export default components
