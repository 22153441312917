import { base } from './base'

const fonts = {
  heading: `system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `system-ui, "Noto Sans Thai" , "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
}

const fontSizes = {
  xs: base.size.xs,
  sm: base.size.sm,
  md: base.size.md,
  lg: base.size.lg,
  lg2: base.size.lg2,
  xl: base.size.xl,
  '2xl': base.size['2xl'],
  '2xl2': base.size['2xl2'],
  '3xl': base.size['3xl'],
  '3xl2': base.size['3xl2'],
  '4xl': base.size['4xl'],
  '4xl2': base.size['4xl'],
  '5xl': base.size['5xl'],
  '6xl': base.size['6xl'],
  '6xl2': base.size['6xl2'],
  '7xl': base.size['7xl'],
  '8xl': base.size['8xl'],
  '9xl': base.size['9xl'],
}

export { fonts, fontSizes }
